<template>
  <div class="api-key__popup-root">
    <div class="api-key__popup-bg" @click="closePopup"/>
    <div class="api-key__popup">
      <div class="api-key__title"> Изменить API </div>
      <div class="api-key__input-block">
        <label class="api-key__input-label"> Текущий API </label>
        <input class="api-key__input"/>
      </div>
      <div class="api-key__input-block">
        <label class="api-key__input-label"> Пароль </label>
        <input class="api-key__input"/>
      </div>
      <button class="api-key__submit-button btn primary" @click="changeApi">Сохранить</button>
    </div>
  </div>
</template>

<script setup lang="ts">
import {changeApiKeyPopupStore, changeApiKeyPopupStore as store} from '~/stores/changeApiKey-popup-store';
import {useUserAuth} from "~/composables/user/useUserAuth";
const { $i18n, $toast } = useNuxtApp();
const { closePopup } = changeApiKeyPopupStore();

const {
  currentUser,
  userChangeApiKey
} = useUserAuth();

async function changeApi () {
  const res = await userChangeApiKey();

  if (res && res.status === 'ok') {
    $toast.success('ApiKey успешно изменен');
  }
}
</script>

<style scoped>
.api-key__popup-root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  z-index: 997;
  align-items: center;
  padding: 15% 0;
  //border-radius: 24px;
}
.api-key__popup-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.75);
  transition: 0.5s all;
}
.api-key__popup {
  background: white;
  width: 30%;
  border-radius: 24px;
  padding: 42px;
  display: flex;
  flex-direction: column;
  transition: 0.5s all;
  z-index: 1;
  overflow: hidden;
  box-shadow: 0px 4px 4px 0px #00000040;
  gap: 30px;
}
.api-key__title {
  color: #333333;
  font-size: 40px;
  font-weight: 600;
  line-height: 46.88px;
  text-align: center;
  padding-bottom: 2px;
}
.api-key__input-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.api-key__input-label {
  color: #666666;
  font-size: 18px;
  line-height: 21.09px;
  font-weight: 600;
  text-align: center;
}
.api-key__input {
  border-radius: 12px;
}
.api-key__submit-button {
  display: flex;
  width: 100%;
}
</style>
